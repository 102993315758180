<template>
    <div class="app-container eventregistration">
        <div class="filter-line">
            <span>
                <el-button type="primary" icon="el-icon-plus" @click="addApplicationForm">新建报名</el-button>
                <el-button @click="exportHandler">{{ this.$t("commons.export") }}</el-button>
                <el-button icon="el-icon-refresh-right" @click="getTableList">{{ this.$t("commons.refresh") }}</el-button>
            </span>
            <div class="header-filter-button" @click="dataset.filterLine.show = !dataset.filterLine.show"
                :class="dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'">
                <i class="iconfont icon-loudoutu"></i>筛选
            </div>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler"></finalTable>
        </div>
        <div>
            <el-dialog :visible.sync="activityObj.dialogForm" width="728px" :before-close="handleClose"
                :close-on-click-modal="false">
                <template slot="title">
                    <span>{{ activityObj.dialogTitle }}</span>
                </template>
                <evenDetail v-if="activityObj.dialogForm" :dialogStatus="activityObj.dialogStatus" :id="activityObj.id"
                    @close="handleClose"></evenDetail>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import evenDetail from "./evenDetail";
import { dateFormat } from "@/filters/index";
import {
    getActivityList,
    deleteActivityEnrollment,
    downloadServiceGuide,
    activityListExport
} from "@/api/ruge/gsPark/customerService/eventregistration.js";
import { envInfo } from "@/constants/envInfo";
export default {
    name: "eventregistration",
    components: {
        finalTable,
        evenDetail
    },
    data() {
        return {
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                header: [
                    { prop: "activityTitle", label: "活动标题", width: "" },
                    { prop: "activityPlace", label: "活动地点", width: "240" },
                    { prop: "startTime", label: "活动开始时间", width: "" },
                    { prop: "endTime", label: "活动结束时间", width: "" },
                    { prop: "enrollmentStatus", label: "报名状态", width: "" },
                    { prop: "enrollmentCount", label: "报名人数", width: "" },
                    { prop: "operation", label: "操作", width: "200" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    activityTitle: {
                        type: "input",
                        label: "活动标题",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入活动标题",
                        prefixIcon: "el-icon-search",
                    },
                    activityPlace: {
                        type: "input",
                        label: "活动地点",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    // startTime: {
                    //     type: "input",
                    //     label: "活动开始时间",
                    //     value: "",
                    //     actionType: "goSearch",
                    //     // optionList: [],
                    //     multiple: true,
                    //     collapseTags: true,
                    //     prefixIcon: "el-icon-search",
                    // },
                    // endTime: {
                    //     type: "numberInput",
                    //     label: "活动结束时间",
                    //     value: "",
                    //     actionType: "goSearch",
                    //     placeholder: "",
                    //     prefixIcon: "el-icon-search",
                    // },
                    enrollmentStatus: {
                        type: "selector",
                        label: "报名状态",
                        value: "",
                        optionList: [
                            {
                                label: "未开始",
                                value: "notStart",
                            },
                            {
                                label: "报名中",
                                value: "start",
                            },
                            {
                                label: "已截至",
                                value: "end",
                            },
                        ],
                        actionType: "goSearch",
                    },
                    // enrollmentCount: {
                    //     type: "numberInput",
                    //     label: "报名人数",
                    //     value: "",
                    //     actionType: "goSearch",
                    //     placeholder: "请输入",
                    //     prefixIcon: "el-icon-search",
                    // },
                    operation: {
                        type: "button",
                        filterCount: "",
                        actionType: "clickEvent",
                        eventName: "showMoreFilters",
                        label: "更多筛选",
                    },
                },
                // 表格内容配置
                detailConfig: {
                    startTime: {
                        type: "dateFormat",
                    },
                    endTime: {
                        type: "dateFormat",
                    },
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "查看",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "edit",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "编辑",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "download",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "下载二维码",
                                // showProp: "destinedStatus",
                                // showValue: 1,
                            },
                            {
                                actionType: "iconClick",
                                eventName: "delete",
                                fontSize: "14px",
                                color: "#E47470",
                                cursorPointer: "pointer",
                                iconName: "el-icon-delete",
                                tooltips: "删除",
                            },
                        ],
                    },
                },
                // 高级搜索配置
                advanceFilterConfig: {
                    startTime: {
                        type: "dateRange",
                        pickerType: "date",
                        label: "活动开始时间",
                        value: [],
                    },
                    endTime: {
                        type: "dateRange",
                        pickerType: "date",
                        label: "活动结束时间",
                        value: [],
                    },
                },
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            activityObj: {
                tableLoading: false,
                dialogVisible: false,
                dialogForm: false,
                typeFlag: null,
                dialogStatus: "add",
                dialogTitle: '新建报名',
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    activityTitle: null,
                    activityPlace: null,
                    enrollStartTime: null,
                    enrollEndTime: null,
                    startTime: null,
                    endTime: null,
                    enrollmentStatus: null,
                    enrollmentCount: null,
                }
            },
        }
    },
    created() {
        this.getTableList()
    },
    methods: {
        // 初始化数据
        getTableList() {
            this.activityObj.tableLoading = true;
            const params = { ...this.activityObj.listQuery };
            for (let pn in params) {
                !params[pn] && params[pn] !== 0 && (params[pn] = null);
            }
            getActivityList(params).then((res) => {
                this.dataset.pageVO.total = res.data.total;
                this.dataset.tableData = res.data.rows;
                console.log(res, 'res');
            }).finally(() => {
                this.activityObj.tableLoading = false;
            });
        },
        // table组件页面事件配置
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.dealStartTime(datas);
                this.dealEndTime(datas);
                // 更新数据
                this.activityObj.listQuery = {
                    ...this.activityObj.listQuery,
                    ...datas.params,
                };
                this.activityObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;
                this.getTableList();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "edit":
                        this.activityObj.id = datas.row.id;
                        this.activityObj.dialogTitle = '活动编辑'
                        this.activityObj.dialogStatus = "edit";
                        this.activityObj.typeFlag = datas.row.typeFlag;
                        this.activityObj.dialogForm = true;
                        break;
                    case "delete":
                        this.singleDeleteHandler(datas.row);
                        break;
                    case "view":
                        this.tbRowDetail(datas.row);
                        break;
                    case "download":
                        this.downloadHandler(datas.row);
                        break;
                   
                }
            } else if (datas.type === "paginationChange") {
                // 配置分页
                this.activityObj.listQuery.current = datas.params.current.page;
                this.activityObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            }

        },
        dealStartTime(datas) {
            if (datas.params.startTime && datas.params.startTime.length > 0) {
                this.activityObj.listQuery.startTimeQueryStartDate = datas.params.startTime[0]
                    ? new Date(datas.params.startTime[0]).getTime()
                    : null;
                this.activityObj.listQuery.startTimeQueryEndDate = datas.params.startTime[1]
                    ? new Date(datas.params.startTime[1]).getTime()
                    : null;
                delete datas.params.startTime;
            }
            // else {
            //   this.requestParams.startDate = null;
            //   this.requestParams.endDate = null;
            // }
            return datas;
        },
        dealEndTime(datas) {
            if (datas.params.endTime && datas.params.endTime.length > 0) {
                this.activityObj.listQuery.endTimeQueryStartDate = datas.params.endTime[0]
                    ? new Date(datas.params.endTime[0]).getTime()
                    : null;
                this.activityObj.listQuery.endTimeQueryEndDate = datas.params.endTime[1]
                    ? new Date(datas.params.endTime[1]).getTime()
                    : null;
                delete datas.params.endTime;
            }
            // else {
            //   this.requestParams.startDate = null;
            //   this.requestParams.endDate = null;
            // }
            return datas;
        },
        // 新建报名
        addApplicationForm() {
            this.activityObj.dialogForm = true
            this.activityObj.id = null;
            this.activityObj.dialogStatus = "add";
        },
        handleClose(freshFlag) {
            this.activityObj.dialogTitle = '新建报名'
            console.log(freshFlag, 'freshFlag');
            this.activityObj.dialogForm = false
            freshFlag && this.getTableList();
        },
        singleDeleteHandler(row) {
            this.deleteHandler([row.id]);
        },
        // 删除功能
        deleteHandler(ids) {
            this.$confirm("删除后将不可恢复，确定删除么？", this.$t("commons.warning"), {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
            }).then(() => {
                deleteActivityEnrollment(ids).then((res) => {
                    if (res.code === 200) {
                        this.$message.success("删除成功！");
                        this.getTableList();
                    } else {
                        this.$message.warning("删除失败！");
                    }
                });
            })
                .catch((error) => {
                    console.log(`未删除，原因 => ${error}`);
                });
        },
        // 查看详情
        tbRowDetail(row) {
            console.log(row);
            let params = {
                path: '/gsPark/informationDelivery/eventregistration/detail',
                query: {
                    isLook: true,
                    dataId: row.id,
                }
            }
            this.$router.push(params)
        },
        // 下载二维码
        downloadHandler({ id, imageId }) {
            //下载文件
            const url =
                envInfo.bgApp.archivePath +
                "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
                imageId;
            const asemName = "jpg";
            const element = document.createElement("a");
            element.setAttribute("href", url);
            // element.setAttribute("download", asemName);
            element.setAttribute("download", `image.${asemName}`);
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);

            // downloadServiceGuide({ id }).then(() => {
            //     this.getTableList();
            // });
        },
        // 导出
        exportHandler() {
            activityListExport(this.activityObj.listQuery).then((taskId) => {
                this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                });
                let exportObj = {
                    taskId,
                    taskName: "活动报名",
                    taskStatus: 0,
                    rootPath: "customerGSPath",
                };
                this.$store.dispatch("PushExportNotice", exportObj);
            });
        },
    },
    watch: {
        activityObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        },
    }
}
</script>
<style lang="less" scoped>
.eventregistration {
    .filter-line {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            height: 36px;
        }

        .icon-loudoutu {
            font-size: 12px;
        }

        .header-filter-button {
            cursor: pointer;
            width: 84px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 3px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        .filter-button-open {
            color: #409eff;
            background: #ecf5ff;
            border: 1px solid #c6e2ff;
        }

        .filter-button-close {
            color: #2a4158;
            background: #ffffff;
            border: 1px solid #e7e8eb;
        }
    }
}
</style>